import { default as indexiT0o23yrfFMeta } from "/vercel/path0/pages/[slug]-carat-diamond-rings/index.vue?macro=true";
import { default as indexZunLnKiW2QMeta } from "/vercel/path0/pages/[slug]-engagement-rings/index.vue?macro=true";
import { default as indexJJNmMSf2WnMeta } from "/vercel/path0/pages/[type]/[slug]/index.vue?macro=true";
import { default as indexbVZVe2F5meMeta } from "/vercel/path0/pages/[type]/index.vue?macro=true";
import { default as _4_45cs_45diamondr4mRQc65ScMeta } from "/vercel/path0/pages/4-cs-diamond.vue?macro=true";
import { default as about_45usxh4Yn5B3LnMeta } from "/vercel/path0/pages/about-us.vue?macro=true";
import { default as change_45passwordvMQoHGFKWbMeta } from "/vercel/path0/pages/account/change-password.vue?macro=true";
import { default as detailLiX8I4tzfOMeta } from "/vercel/path0/pages/account/detail.vue?macro=true";
import { default as _91id_93vsCajmochyMeta } from "/vercel/path0/pages/account/orders/[id].vue?macro=true";
import { default as indexrVzRtN8So8Meta } from "/vercel/path0/pages/account/orders/index.vue?macro=true";
import { default as appointmentg216JUSXNDMeta } from "/vercel/path0/pages/appointment.vue?macro=true";
import { default as _91_91sub_type_93_93WMUYtYFi3DMeta } from "/vercel/path0/pages/blog/[[type]]/[[sub_type]].vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as cart3AXNnOUus9Meta } from "/vercel/path0/pages/cart.vue?macro=true";
import { default as address_46clientjA4WYErpSpMeta } from "/vercel/path0/pages/checkout/address.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as payment_46clientsOSrHGaPjLMeta } from "/vercel/path0/pages/checkout/payment.client.vue?macro=true";
import { default as thank_45youXCXwOlLCe7Meta } from "/vercel/path0/pages/checkout/thank-you.vue?macro=true";
import { default as contact_45usJctFDXjrwzMeta } from "/vercel/path0/pages/contact-us.vue?macro=true";
import { default as cookie_45policywxwm0wEQp5Meta } from "/vercel/path0/pages/cookie-policy.vue?macro=true";
import { default as buyback_45policynQGot0rm4uMeta } from "/vercel/path0/pages/customer-service/buyback-policy.vue?macro=true";
import { default as exchange_45policyr46oTRstWPMeta } from "/vercel/path0/pages/customer-service/exchange-policy.vue?macro=true";
import { default as indexDcLAnvHuicMeta } from "/vercel/path0/pages/customer-service/index.vue?macro=true";
import { default as life_45time_45warrantyT6ZIH8XuWvMeta } from "/vercel/path0/pages/customer-service/life-time-warranty.vue?macro=true";
import { default as payment_45optionsFm9uokj12rMeta } from "/vercel/path0/pages/customer-service/payment-options.vue?macro=true";
import { default as return_45policyiFBKeiccRvMeta } from "/vercel/path0/pages/customer-service/return-policy.vue?macro=true";
import { default as ring_45engravingwleFYFKVFxMeta } from "/vercel/path0/pages/customer-service/ring-engraving.vue?macro=true";
import { default as ring_45resizingdYtTFoqVjFMeta } from "/vercel/path0/pages/customer-service/ring-resizing.vue?macro=true";
import { default as shipping_45policyCeBibu6jjlMeta } from "/vercel/path0/pages/customer-service/shipping-policy.vue?macro=true";
import { default as customized_45jewelryBmJDy54yraMeta } from "/vercel/path0/pages/customized-jewelry.vue?macro=true";
import { default as indexCxirNSUYHjMeta } from "/vercel/path0/pages/diamond-jewelry/[[start_with]]/index.vue?macro=true";
import { default as _91_91start_with_93_93e2gnCg59x2Meta } from "/vercel/path0/pages/earring-settings/[[start_with]].vue?macro=true";
import { default as indexPWBaIrRiRiMeta } from "/vercel/path0/pages/earring-settings/[slug]/index.vue?macro=true";
import { default as completeZGXYtVPpG3Meta } from "/vercel/path0/pages/earrings/complete.vue?macro=true";
import { default as engagement_45ring_45for_45womenU6Si0H7FVeMeta } from "/vercel/path0/pages/engagement-ring-for-women.vue?macro=true";
import { default as complete_45the_45ringUB8PO0AfB0Meta } from "/vercel/path0/pages/engagement-ring-guide/complete-the-ring.vue?macro=true";
import { default as indexCCgeAtbt6MMeta } from "/vercel/path0/pages/engagement-ring-guide/index.vue?macro=true";
import { default as ring_45costrRaz52kbsWMeta } from "/vercel/path0/pages/engagement-ring-guide/ring-cost.vue?macro=true";
import { default as time_45to_45proposeZLsV14xUW2Meta } from "/vercel/path0/pages/engagement-ring-guide/time-to-propose.vue?macro=true";
import { default as types_45of_45diamondsBWTAIdgAEzMeta } from "/vercel/path0/pages/engagement-ring-guide/types-of-diamonds.vue?macro=true";
import { default as types_45of_45ring_45settingsxm9e8soD3sMeta } from "/vercel/path0/pages/engagement-ring-guide/types-of-ring-settings.vue?macro=true";
import { default as completeGKVBM9l73nMeta } from "/vercel/path0/pages/engagement-ring/complete.vue?macro=true";
import { default as engagement_45ringsZ95g4tAW9BMeta } from "/vercel/path0/pages/engagement-rings.vue?macro=true";
import { default as ethical_45and_45conflict_45free_45diamondsTLEwmWn1EeMeta } from "/vercel/path0/pages/ethical-and-conflict-free-diamonds.vue?macro=true";
import { default as fine_45jewelry_45newAF4Trk8PuhMeta } from "/vercel/path0/pages/fine-jewelry-new.vue?macro=true";
import { default as hoop_45earringsIA97MsMPYRMeta } from "/vercel/path0/pages/fine-jewelry/hoop-earrings.vue?macro=true";
import { default as indexetsycAH0eHMeta } from "/vercel/path0/pages/fine-jewelry/index.vue?macro=true";
import { default as solitaire_45necklacesOiqnIGVclfMeta } from "/vercel/path0/pages/fine-jewelry/solitaire-necklaces.vue?macro=true";
import { default as stud_45earringsb7JKOlAzRDMeta } from "/vercel/path0/pages/fine-jewelry/stud-earrings.vue?macro=true";
import { default as tennis_45bracelets04wVguti81Meta } from "/vercel/path0/pages/fine-jewelry/tennis-bracelets.vue?macro=true";
import { default as tennis_45necklacesFpwoGCm8z3Meta } from "/vercel/path0/pages/fine-jewelry/tennis-necklaces.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as franchiseFY7Nm16vpwMeta } from "/vercel/path0/pages/franchise.vue?macro=true";
import { default as free_45productIhlBupOfOoMeta } from "/vercel/path0/pages/free-product.vue?macro=true";
import { default as friendly_45diamond_45differencemF89cGf8IQMeta } from "/vercel/path0/pages/friendly-diamond-difference.vue?macro=true";
import { default as green_45and_45sustainable_45giftmJWvvAKJntMeta } from "/vercel/path0/pages/green-and-sustainable-gift.vue?macro=true";
import { default as how_45to_45find_45ring_45sizeym3YxRUEYWMeta } from "/vercel/path0/pages/how-to-find-ring-size.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexRZmXLXmoeyMeta } from "/vercel/path0/pages/inspiration-[slug]/index.vue?macro=true";
import { default as _91_91start_with_93_93JgBudGWz7EMeta } from "/vercel/path0/pages/lab-diamond-earrings/[[start_with]].vue?macro=true";
import { default as indexsrSrj3Fc0aMeta } from "/vercel/path0/pages/lab-diamond-earrings/[slug]/index.vue?macro=true";
import { default as _91_91start_with_93_93CSL5ineuR6Meta } from "/vercel/path0/pages/lab-diamond-pendants/[[start_with]].vue?macro=true";
import { default as indexJYS3tEcoD9Meta } from "/vercel/path0/pages/lab-diamond-pendants/[slug]/index.vue?macro=true";
import { default as _91_91start_with_93_93whJWZ5lF9vMeta } from "/vercel/path0/pages/lab-diamond-rings/[[start_with]].vue?macro=true";
import { default as indexKWmWqeJvLUMeta } from "/vercel/path0/pages/lab-diamond-rings/[slug]/index.vue?macro=true";
import { default as lab_45diamondsz0fxWGmRaiMeta } from "/vercel/path0/pages/lab-diamonds.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91_91start_with_93_93Apk0AE2nNiMeta } from "/vercel/path0/pages/loose-diamonds/[[start_with]].vue?macro=true";
import { default as indexqEQrUA0oEZMeta } from "/vercel/path0/pages/loose-diamonds/[slug]/index.vue?macro=true";
import { default as our_45share_45of_45careX0PWfimDT8Meta } from "/vercel/path0/pages/our-share-of-care.vue?macro=true";
import { default as our_45storyec5eTC1Ku0Meta } from "/vercel/path0/pages/our-story.vue?macro=true";
import { default as _91_91start_with_93_93gMSIHD5mRUMeta } from "/vercel/path0/pages/pendant-settings/[[start_with]].vue?macro=true";
import { default as indexzFwhdae4p7Meta } from "/vercel/path0/pages/pendant-settings/[slug]/index.vue?macro=true";
import { default as completeEcmo5w6JKiMeta } from "/vercel/path0/pages/pendant/complete.vue?macro=true";
import { default as indexVBrk85hVoKMeta } from "/vercel/path0/pages/preset-engagement-rings/[slug]/index.vue?macro=true";
import { default as indexs53PJ3aVoKMeta } from "/vercel/path0/pages/preset-engagement-rings/index.vue?macro=true";
import { default as pressJq1FmNOBviMeta } from "/vercel/path0/pages/press.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as indexdwIMIuu1flMeta } from "/vercel/path0/pages/ready-to-ship-engagement-rings/index.vue?macro=true";
import { default as indexTX515R0WjrMeta } from "/vercel/path0/pages/ready-to-ship-pendants/index.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as _91_91start_with_93_93e3m5c89CFAMeta } from "/vercel/path0/pages/ring-settings/[[start_with]].vue?macro=true";
import { default as indexiSGMojciAKMeta } from "/vercel/path0/pages/ring-settings/[slug]/index.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as terms_45of_45serviceSmXvkgSRNCMeta } from "/vercel/path0/pages/terms-of-service.vue?macro=true";
import { default as indexUZnlnEsGSFMeta } from "/vercel/path0/pages/testimonials/[slug]/index.vue?macro=true";
import { default as indexjuqlSSI0TvMeta } from "/vercel/path0/pages/testimonials/index.vue?macro=true";
import { default as indexqQwoJeOhndMeta } from "/vercel/path0/pages/toi-et-moi-engagement-rings/index.vue?macro=true";
import { default as wedding_45rings_45oldc2yoUSCY82Meta } from "/vercel/path0/pages/wedding-rings-old.vue?macro=true";
import { default as anniversary_45rings1x6wNWHSM4Meta } from "/vercel/path0/pages/wedding-rings/anniversary-rings.vue?macro=true";
import { default as couple_45ringsSzdOwO2PhHMeta } from "/vercel/path0/pages/wedding-rings/couple-rings.vue?macro=true";
import { default as eternity_45ringsZmRqvaILBBMeta } from "/vercel/path0/pages/wedding-rings/eternity-rings.vue?macro=true";
import { default as indexRBH6TSDFabMeta } from "/vercel/path0/pages/wedding-rings/index.vue?macro=true";
import { default as jewelry_45engagement_45ringsCbRHgrj1GyMeta } from "/vercel/path0/pages/wedding-rings/jewelry-engagement-rings.vue?macro=true";
import { default as womens_45wedding_45ringsb8KFpPKxkKMeta } from "/vercel/path0/pages/wedding-rings/womens-wedding-rings.vue?macro=true";
export default [
  {
    name: "recently-purchase-carat___us",
    path: "/:slug()-carat-diamond-rings",
    meta: indexiT0o23yrfFMeta || {},
    component: () => import("/vercel/path0/pages/[slug]-carat-diamond-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "recently-purchase-carat___in",
    path: "/in/:slug()-carat-diamond-rings",
    meta: indexiT0o23yrfFMeta || {},
    component: () => import("/vercel/path0/pages/[slug]-carat-diamond-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "recently-purchase-shape___us",
    path: "/:slug()-engagement-rings",
    meta: indexZunLnKiW2QMeta || {},
    component: () => import("/vercel/path0/pages/[slug]-engagement-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "recently-purchase-shape___in",
    path: "/in/:slug()-engagement-rings",
    meta: indexZunLnKiW2QMeta || {},
    component: () => import("/vercel/path0/pages/[slug]-engagement-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-detail___us",
    path: "/:type()/:slug()",
    meta: indexJJNmMSf2WnMeta || {},
    component: () => import("/vercel/path0/pages/[type]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-detail___in",
    path: "/in/:type()/:slug()",
    meta: indexJJNmMSf2WnMeta || {},
    component: () => import("/vercel/path0/pages/[type]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "type___us",
    path: "/:type()",
    meta: indexbVZVe2F5meMeta || {},
    component: () => import("/vercel/path0/pages/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "type___in",
    path: "/in/:type()",
    meta: indexbVZVe2F5meMeta || {},
    component: () => import("/vercel/path0/pages/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "4-cs-diamond___us",
    path: "/4-cs-diamond",
    component: () => import("/vercel/path0/pages/4-cs-diamond.vue").then(m => m.default || m)
  },
  {
    name: "4-cs-diamond___in",
    path: "/in/4-cs-diamond",
    component: () => import("/vercel/path0/pages/4-cs-diamond.vue").then(m => m.default || m)
  },
  {
    name: "about-us___us",
    path: "/about-us",
    component: () => import("/vercel/path0/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___in",
    path: "/in/about-us",
    component: () => import("/vercel/path0/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___us",
    path: "/account/change-password",
    meta: change_45passwordvMQoHGFKWbMeta || {},
    component: () => import("/vercel/path0/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___in",
    path: "/in/account/change-password",
    meta: change_45passwordvMQoHGFKWbMeta || {},
    component: () => import("/vercel/path0/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: "account-detail___us",
    path: "/account/detail",
    meta: detailLiX8I4tzfOMeta || {},
    component: () => import("/vercel/path0/pages/account/detail.vue").then(m => m.default || m)
  },
  {
    name: "account-detail___in",
    path: "/in/account/detail",
    meta: detailLiX8I4tzfOMeta || {},
    component: () => import("/vercel/path0/pages/account/detail.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___us",
    path: "/account/orders/:id()",
    meta: _91id_93vsCajmochyMeta || {},
    component: () => import("/vercel/path0/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___in",
    path: "/in/account/orders/:id()",
    meta: _91id_93vsCajmochyMeta || {},
    component: () => import("/vercel/path0/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___us",
    path: "/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___in",
    path: "/in/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "appointment___us",
    path: "/appointment",
    component: () => import("/vercel/path0/pages/appointment.vue").then(m => m.default || m)
  },
  {
    name: "appointment___in",
    path: "/in/appointment",
    component: () => import("/vercel/path0/pages/appointment.vue").then(m => m.default || m)
  },
  {
    name: "blog-listing___us",
    path: "/blog/:type?/:sub_type?",
    meta: _91_91sub_type_93_93WMUYtYFi3DMeta || {},
    component: () => import("/vercel/path0/pages/blog/[[type]]/[[sub_type]].vue").then(m => m.default || m)
  },
  {
    name: "blog-listing___in",
    path: "/in/blog/:type?/:sub_type?",
    meta: _91_91sub_type_93_93WMUYtYFi3DMeta || {},
    component: () => import("/vercel/path0/pages/blog/[[type]]/[[sub_type]].vue").then(m => m.default || m)
  },
  {
    name: "blog-detail___us",
    path: "/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-detail___in",
    path: "/in/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cart___us",
    path: "/cart",
    component: () => import("/vercel/path0/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___in",
    path: "/in/cart",
    component: () => import("/vercel/path0/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-address___us",
    path: "/checkout/address",
    component: () => createClientPage(() => import("/vercel/path0/pages/checkout/address.client.vue").then(m => m.default || m))
  },
  {
    name: "checkout-address___in",
    path: "/in/checkout/address",
    component: () => createClientPage(() => import("/vercel/path0/pages/checkout/address.client.vue").then(m => m.default || m))
  },
  {
    name: "checkout-payment___us",
    path: "/checkout/payment",
    component: () => createClientPage(() => import("/vercel/path0/pages/checkout/payment.client.vue").then(m => m.default || m))
  },
  {
    name: "checkout-payment___in",
    path: "/in/checkout/payment",
    component: () => createClientPage(() => import("/vercel/path0/pages/checkout/payment.client.vue").then(m => m.default || m))
  },
  {
    name: "checkout-thank-you___us",
    path: "/checkout/thank-you",
    component: () => import("/vercel/path0/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "checkout-thank-you___in",
    path: "/in/checkout/thank-you",
    component: () => import("/vercel/path0/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___us",
    path: "/contact-us",
    component: () => import("/vercel/path0/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___in",
    path: "/in/contact-us",
    component: () => import("/vercel/path0/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___us",
    path: "/cookie-policy",
    component: () => import("/vercel/path0/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___in",
    path: "/in/cookie-policy",
    component: () => import("/vercel/path0/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-buyback-policy___us",
    path: "/customer-service/buyback-policy",
    component: () => import("/vercel/path0/pages/customer-service/buyback-policy.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-buyback-policy___in",
    path: "/in/customer-service/buyback-policy",
    component: () => import("/vercel/path0/pages/customer-service/buyback-policy.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-exchange-policy___us",
    path: "/customer-service/exchange-policy",
    component: () => import("/vercel/path0/pages/customer-service/exchange-policy.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-exchange-policy___in",
    path: "/in/customer-service/exchange-policy",
    component: () => import("/vercel/path0/pages/customer-service/exchange-policy.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___us",
    path: "/customer-service",
    component: () => import("/vercel/path0/pages/customer-service/index.vue").then(m => m.default || m)
  },
  {
    name: "customer-service___in",
    path: "/in/customer-service",
    component: () => import("/vercel/path0/pages/customer-service/index.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-life-time-warranty___us",
    path: "/customer-service/life-time-warranty",
    component: () => import("/vercel/path0/pages/customer-service/life-time-warranty.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-life-time-warranty___in",
    path: "/in/customer-service/life-time-warranty",
    component: () => import("/vercel/path0/pages/customer-service/life-time-warranty.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-payment-options___us",
    path: "/customer-service/payment-options",
    component: () => import("/vercel/path0/pages/customer-service/payment-options.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-payment-options___in",
    path: "/in/customer-service/payment-options",
    component: () => import("/vercel/path0/pages/customer-service/payment-options.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-return-policy___us",
    path: "/customer-service/return-policy",
    component: () => import("/vercel/path0/pages/customer-service/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-return-policy___in",
    path: "/in/customer-service/return-policy",
    component: () => import("/vercel/path0/pages/customer-service/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-ring-engraving___us",
    path: "/customer-service/ring-engraving",
    component: () => import("/vercel/path0/pages/customer-service/ring-engraving.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-ring-engraving___in",
    path: "/in/customer-service/ring-engraving",
    component: () => import("/vercel/path0/pages/customer-service/ring-engraving.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-ring-resizing___us",
    path: "/customer-service/ring-resizing",
    component: () => import("/vercel/path0/pages/customer-service/ring-resizing.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-ring-resizing___in",
    path: "/in/customer-service/ring-resizing",
    component: () => import("/vercel/path0/pages/customer-service/ring-resizing.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-shipping-policy___us",
    path: "/customer-service/shipping-policy",
    component: () => import("/vercel/path0/pages/customer-service/shipping-policy.vue").then(m => m.default || m)
  },
  {
    name: "customer-service-shipping-policy___in",
    path: "/in/customer-service/shipping-policy",
    component: () => import("/vercel/path0/pages/customer-service/shipping-policy.vue").then(m => m.default || m)
  },
  {
    name: "customized-jewelry___us",
    path: "/customized-jewelry",
    component: () => import("/vercel/path0/pages/customized-jewelry.vue").then(m => m.default || m)
  },
  {
    name: "customized-jewelry___in",
    path: "/in/customise-jewellery",
    component: () => import("/vercel/path0/pages/customized-jewelry.vue").then(m => m.default || m)
  },
  {
    name: "shop-all___us",
    path: "/diamond-jewelry/:start_with?",
    meta: indexCxirNSUYHjMeta || {},
    component: () => import("/vercel/path0/pages/diamond-jewelry/[[start_with]]/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-all___in",
    path: "/in/diamond-jewellery/:start_with?",
    meta: indexCxirNSUYHjMeta || {},
    component: () => import("/vercel/path0/pages/diamond-jewelry/[[start_with]]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-list-earring___us",
    path: "/earring-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93e2gnCg59x2Meta || {},
    component: () => import("/vercel/path0/pages/earring-settings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-list-earring___in",
    path: "/in/earring-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93e2gnCg59x2Meta || {},
    component: () => import("/vercel/path0/pages/earring-settings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-detail-earring___us",
    path: "/earring-settings/:slug(.*-\\d+)?",
    meta: indexPWBaIrRiRiMeta || {},
    component: () => import("/vercel/path0/pages/earring-settings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-detail-earring___in",
    path: "/in/earring-settings/:slug(.*-\\d+)?",
    meta: indexPWBaIrRiRiMeta || {},
    component: () => import("/vercel/path0/pages/earring-settings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-complete-earring___us",
    path: "/earrings/complete",
    meta: completeZGXYtVPpG3Meta || {},
    component: () => import("/vercel/path0/pages/earrings/complete.vue").then(m => m.default || m)
  },
  {
    name: "cyo-complete-earring___in",
    path: "/in/earrings/complete",
    meta: completeZGXYtVPpG3Meta || {},
    component: () => import("/vercel/path0/pages/earrings/complete.vue").then(m => m.default || m)
  },
  {
    name: "recently-purchase-all___us",
    path: "/engagement-ring-for-women",
    meta: engagement_45ring_45for_45womenU6Si0H7FVeMeta || {},
    component: () => import("/vercel/path0/pages/engagement-ring-for-women.vue").then(m => m.default || m)
  },
  {
    name: "recently-purchase-all___in",
    path: "/in/engagement-ring-for-women",
    meta: engagement_45ring_45for_45womenU6Si0H7FVeMeta || {},
    component: () => import("/vercel/path0/pages/engagement-ring-for-women.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-complete-the-ring___us",
    path: "/engagement-ring-guide/complete-the-ring",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/complete-the-ring.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-complete-the-ring___in",
    path: "/in/engagement-ring-guide/complete-the-ring",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/complete-the-ring.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide___us",
    path: "/engagement-ring-guide",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/index.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide___in",
    path: "/in/engagement-ring-guide",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/index.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-ring-cost___us",
    path: "/engagement-ring-guide/ring-cost",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/ring-cost.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-ring-cost___in",
    path: "/in/engagement-ring-guide/ring-cost",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/ring-cost.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-time-to-propose___us",
    path: "/engagement-ring-guide/time-to-propose",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/time-to-propose.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-time-to-propose___in",
    path: "/in/engagement-ring-guide/time-to-propose",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/time-to-propose.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-types-of-diamonds___us",
    path: "/engagement-ring-guide/types-of-diamonds",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/types-of-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-types-of-diamonds___in",
    path: "/in/engagement-ring-guide/types-of-diamonds",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/types-of-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-types-of-ring-settings___us",
    path: "/engagement-ring-guide/types-of-ring-settings",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/types-of-ring-settings.vue").then(m => m.default || m)
  },
  {
    name: "engagement-ring-guide-types-of-ring-settings___in",
    path: "/in/engagement-ring-guide/types-of-ring-settings",
    component: () => import("/vercel/path0/pages/engagement-ring-guide/types-of-ring-settings.vue").then(m => m.default || m)
  },
  {
    name: "cyo-complete-ring___us",
    path: "/engagement-ring/complete",
    meta: completeGKVBM9l73nMeta || {},
    component: () => import("/vercel/path0/pages/engagement-ring/complete.vue").then(m => m.default || m)
  },
  {
    name: "cyo-complete-ring___in",
    path: "/in/engagement-ring/complete",
    meta: completeGKVBM9l73nMeta || {},
    component: () => import("/vercel/path0/pages/engagement-ring/complete.vue").then(m => m.default || m)
  },
  {
    name: "engagement-rings___us",
    path: "/engagement-rings",
    meta: engagement_45ringsZ95g4tAW9BMeta || {},
    component: () => import("/vercel/path0/pages/engagement-rings.vue").then(m => m.default || m)
  },
  {
    name: "engagement-rings___in",
    path: "/in/engagement-rings",
    meta: engagement_45ringsZ95g4tAW9BMeta || {},
    component: () => import("/vercel/path0/pages/engagement-rings.vue").then(m => m.default || m)
  },
  {
    name: "ethical-and-conflict-free-diamonds___us",
    path: "/ethical-and-conflict-free-diamonds",
    component: () => import("/vercel/path0/pages/ethical-and-conflict-free-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "ethical-and-conflict-free-diamonds___in",
    path: "/in/ethical-and-conflict-free-diamonds",
    component: () => import("/vercel/path0/pages/ethical-and-conflict-free-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "fine-jewelry-new___us",
    path: "/fine-jewelry-new",
    component: () => import("/vercel/path0/pages/fine-jewelry-new.vue").then(m => m.default || m)
  },
  {
    name: "fine-jewelry-new___in",
    path: "/in/fine-jewelry-new",
    component: () => import("/vercel/path0/pages/fine-jewelry-new.vue").then(m => m.default || m)
  },
  {
    name: "shop-hoop___us",
    path: "/fine-jewelry/hoop-earrings",
    meta: hoop_45earringsIA97MsMPYRMeta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/hoop-earrings.vue").then(m => m.default || m)
  },
  {
    name: "shop-hoop___in",
    path: "/in/fine-jewellery/hoop-earrings",
    meta: hoop_45earringsIA97MsMPYRMeta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/hoop-earrings.vue").then(m => m.default || m)
  },
  {
    name: "fine-jewelry___us",
    path: "/fine-jewelry",
    component: () => import("/vercel/path0/pages/fine-jewelry/index.vue").then(m => m.default || m)
  },
  {
    name: "fine-jewelry___in",
    path: "/in/fine-jewellery",
    component: () => import("/vercel/path0/pages/fine-jewelry/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-solitaire-necklace___us",
    path: "/fine-jewelry/solitaire-necklaces",
    meta: solitaire_45necklacesOiqnIGVclfMeta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/solitaire-necklaces.vue").then(m => m.default || m)
  },
  {
    name: "shop-solitaire-necklace___in",
    path: "/in/fine-jewellery/solitaire-necklaces",
    meta: solitaire_45necklacesOiqnIGVclfMeta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/solitaire-necklaces.vue").then(m => m.default || m)
  },
  {
    name: "shop-earring___us",
    path: "/fine-jewelry/stud-earrings",
    meta: stud_45earringsb7JKOlAzRDMeta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/stud-earrings.vue").then(m => m.default || m)
  },
  {
    name: "shop-earring___in",
    path: "/in/fine-jewellery/stud-earrings",
    meta: stud_45earringsb7JKOlAzRDMeta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/stud-earrings.vue").then(m => m.default || m)
  },
  {
    name: "shop-bracelet___us",
    path: "/fine-jewelry/tennis-bracelets",
    meta: tennis_45bracelets04wVguti81Meta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/tennis-bracelets.vue").then(m => m.default || m)
  },
  {
    name: "shop-bracelet___in",
    path: "/in/fine-jewellery/tennis-bracelets",
    meta: tennis_45bracelets04wVguti81Meta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/tennis-bracelets.vue").then(m => m.default || m)
  },
  {
    name: "shop-tennis-necklace___us",
    path: "/fine-jewelry/tennis-necklaces",
    meta: tennis_45necklacesFpwoGCm8z3Meta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/tennis-necklaces.vue").then(m => m.default || m)
  },
  {
    name: "shop-tennis-necklace___in",
    path: "/in/fine-jewellery/tennis-necklaces",
    meta: tennis_45necklacesFpwoGCm8z3Meta || {},
    component: () => import("/vercel/path0/pages/fine-jewelry/tennis-necklaces.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___us",
    path: "/forgot-password",
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___in",
    path: "/in/forgot-password",
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "franchise___us",
    path: "/franchise",
    meta: franchiseFY7Nm16vpwMeta || {},
    component: () => import("/vercel/path0/pages/franchise.vue").then(m => m.default || m)
  },
  {
    name: "franchise___in",
    path: "/in/franchise",
    meta: franchiseFY7Nm16vpwMeta || {},
    component: () => import("/vercel/path0/pages/franchise.vue").then(m => m.default || m)
  },
  {
    name: "free-product___us",
    path: "/free-product",
    component: () => import("/vercel/path0/pages/free-product.vue").then(m => m.default || m)
  },
  {
    name: "free-product___in",
    path: "/in/free-product",
    component: () => import("/vercel/path0/pages/free-product.vue").then(m => m.default || m)
  },
  {
    name: "friendly-diamond-difference___us",
    path: "/friendly-diamond-difference",
    component: () => import("/vercel/path0/pages/friendly-diamond-difference.vue").then(m => m.default || m)
  },
  {
    name: "friendly-diamond-difference___in",
    path: "/in/friendly-diamond-difference",
    component: () => import("/vercel/path0/pages/friendly-diamond-difference.vue").then(m => m.default || m)
  },
  {
    name: "green-and-sustainable-gift___us",
    path: "/green-and-sustainable-gift",
    component: () => import("/vercel/path0/pages/green-and-sustainable-gift.vue").then(m => m.default || m)
  },
  {
    name: "green-and-sustainable-gift___in",
    path: "/in/green-and-sustainable-gift",
    component: () => import("/vercel/path0/pages/green-and-sustainable-gift.vue").then(m => m.default || m)
  },
  {
    name: "how-to-find-ring-size___us",
    path: "/how-to-find-ring-size",
    component: () => import("/vercel/path0/pages/how-to-find-ring-size.vue").then(m => m.default || m)
  },
  {
    name: "how-to-find-ring-size___in",
    path: "/in/how-to-find-ring-size",
    component: () => import("/vercel/path0/pages/how-to-find-ring-size.vue").then(m => m.default || m)
  },
  {
    name: "index___us",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___in",
    path: "/in",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-slug___us",
    path: "/inspiration-:slug()",
    meta: indexRZmXLXmoeyMeta || {},
    component: () => import("/vercel/path0/pages/inspiration-[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-slug___in",
    path: "/in/inspiration-:slug()",
    meta: indexRZmXLXmoeyMeta || {},
    component: () => import("/vercel/path0/pages/inspiration-[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-list-earring___us",
    path: "/lab-diamond-earrings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93JgBudGWz7EMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-earrings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-list-earring___in",
    path: "/in/lab-diamond-earrings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93JgBudGWz7EMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-earrings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-detail-earring___us",
    path: "/lab-diamond-earrings/:slug(.*-\\d+)?",
    meta: indexsrSrj3Fc0aMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-earrings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-detail-earring___in",
    path: "/in/lab-diamond-earrings/:slug(.*-\\d+)?",
    meta: indexsrSrj3Fc0aMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-earrings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-list-pendant___us",
    path: "/lab-diamond-pendants/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93CSL5ineuR6Meta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-pendants/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-list-pendant___in",
    path: "/in/lab-diamond-pendants/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93CSL5ineuR6Meta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-pendants/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-detail-pendant___us",
    path: "/lab-diamond-pendants/:slug(.*-\\d+)?",
    meta: indexJYS3tEcoD9Meta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-pendants/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-detail-pendant___in",
    path: "/in/lab-diamond-pendants/:slug(.*-\\d+)?",
    meta: indexJYS3tEcoD9Meta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-pendants/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-list-ring___us",
    path: "/lab-diamond-rings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93whJWZ5lF9vMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-rings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-list-ring___in",
    path: "/in/lab-diamond-rings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93whJWZ5lF9vMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-rings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-detail-ring___us",
    path: "/lab-diamond-rings/:slug(.*-\\d+)?",
    meta: indexKWmWqeJvLUMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-rings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-diamond-detail-ring___in",
    path: "/in/lab-diamond-rings/:slug(.*-\\d+)?",
    meta: indexKWmWqeJvLUMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamond-rings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "lab-diamonds___us",
    path: "/lab-diamonds",
    meta: lab_45diamondsz0fxWGmRaiMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "lab-diamonds___in",
    path: "/in/lab-diamonds",
    meta: lab_45diamondsz0fxWGmRaiMeta || {},
    component: () => import("/vercel/path0/pages/lab-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "login___us",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___in",
    path: "/in/login",
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "loose-listing___us",
    path: "/loose-diamonds/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93Apk0AE2nNiMeta || {},
    component: () => import("/vercel/path0/pages/loose-diamonds/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "loose-listing___in",
    path: "/in/loose-diamonds/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93Apk0AE2nNiMeta || {},
    component: () => import("/vercel/path0/pages/loose-diamonds/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "loose-detail___us",
    path: "/loose-diamonds/:slug(.*-\\d+)?",
    meta: indexqEQrUA0oEZMeta || {},
    component: () => import("/vercel/path0/pages/loose-diamonds/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "loose-detail___in",
    path: "/in/loose-diamonds/:slug(.*-\\d+)?",
    meta: indexqEQrUA0oEZMeta || {},
    component: () => import("/vercel/path0/pages/loose-diamonds/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "our-share-of-care___us",
    path: "/our-share-of-care",
    component: () => import("/vercel/path0/pages/our-share-of-care.vue").then(m => m.default || m)
  },
  {
    name: "our-share-of-care___in",
    path: "/in/our-share-of-care",
    component: () => import("/vercel/path0/pages/our-share-of-care.vue").then(m => m.default || m)
  },
  {
    name: "our-story___us",
    path: "/our-story",
    component: () => import("/vercel/path0/pages/our-story.vue").then(m => m.default || m)
  },
  {
    name: "our-story___in",
    path: "/in/our-story",
    component: () => import("/vercel/path0/pages/our-story.vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-list-pendant___us",
    path: "/pendant-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93gMSIHD5mRUMeta || {},
    component: () => import("/vercel/path0/pages/pendant-settings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-list-pendant___in",
    path: "/in/pendant-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93gMSIHD5mRUMeta || {},
    component: () => import("/vercel/path0/pages/pendant-settings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-detail-pendant___us",
    path: "/pendant-settings/:slug(.*-\\d+)?",
    meta: indexzFwhdae4p7Meta || {},
    component: () => import("/vercel/path0/pages/pendant-settings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-detail-pendant___in",
    path: "/in/pendant-settings/:slug(.*-\\d+)?",
    meta: indexzFwhdae4p7Meta || {},
    component: () => import("/vercel/path0/pages/pendant-settings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-complete-pendant___us",
    path: "/pendant/complete",
    meta: completeEcmo5w6JKiMeta || {},
    component: () => import("/vercel/path0/pages/pendant/complete.vue").then(m => m.default || m)
  },
  {
    name: "cyo-complete-pendant___in",
    path: "/in/pendant/complete",
    meta: completeEcmo5w6JKiMeta || {},
    component: () => import("/vercel/path0/pages/pendant/complete.vue").then(m => m.default || m)
  },
  {
    name: "preset-detail___us",
    path: "/preset-engagement-rings/:slug()",
    meta: indexVBrk85hVoKMeta || {},
    component: () => import("/vercel/path0/pages/preset-engagement-rings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "preset-detail___in",
    path: "/in/preset-engagement-rings/:slug()",
    meta: indexVBrk85hVoKMeta || {},
    component: () => import("/vercel/path0/pages/preset-engagement-rings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "preset-listing___us",
    path: "/preset-engagement-rings",
    meta: indexs53PJ3aVoKMeta || {},
    component: () => import("/vercel/path0/pages/preset-engagement-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "preset-listing___in",
    path: "/in/preset-engagement-rings",
    meta: indexs53PJ3aVoKMeta || {},
    component: () => import("/vercel/path0/pages/preset-engagement-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "press___us",
    path: "/press",
    component: () => import("/vercel/path0/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "press___in",
    path: "/in/press",
    component: () => import("/vercel/path0/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___us",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___in",
    path: "/in/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "instock_ring___us",
    path: "/ready-to-ship-engagement-rings",
    meta: indexdwIMIuu1flMeta || {},
    component: () => import("/vercel/path0/pages/ready-to-ship-engagement-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "instock_ring___in",
    path: "/in/ready-to-ship-engagement-rings",
    meta: indexdwIMIuu1flMeta || {},
    component: () => import("/vercel/path0/pages/ready-to-ship-engagement-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "instock_pendant___us",
    path: "/ready-to-ship-pendants",
    meta: indexTX515R0WjrMeta || {},
    component: () => import("/vercel/path0/pages/ready-to-ship-pendants/index.vue").then(m => m.default || m)
  },
  {
    name: "instock_pendant___in",
    path: "/in/ready-to-ship-pendants",
    meta: indexTX515R0WjrMeta || {},
    component: () => import("/vercel/path0/pages/ready-to-ship-pendants/index.vue").then(m => m.default || m)
  },
  {
    name: "register___us",
    path: "/register",
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___in",
    path: "/in/register",
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___us",
    path: "/reset-password",
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___in",
    path: "/in/reset-password",
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-list-ring___us",
    path: "/ring-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93e3m5c89CFAMeta || {},
    component: () => import("/vercel/path0/pages/ring-settings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-list-ring___in",
    path: "/in/ring-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93e3m5c89CFAMeta || {},
    component: () => import("/vercel/path0/pages/ring-settings/[[start_with]].vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-detail-ring___us",
    path: "/ring-settings/:slug(.*-\\d+)?",
    meta: indexiSGMojciAKMeta || {},
    component: () => import("/vercel/path0/pages/ring-settings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cyo-setting-detail-ring___in",
    path: "/in/ring-settings/:slug(.*-\\d+)?",
    meta: indexiSGMojciAKMeta || {},
    component: () => import("/vercel/path0/pages/ring-settings/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "search___us",
    path: "/search",
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___in",
    path: "/in/search",
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___us",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___in",
    path: "/in/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___us",
    path: "/terms-of-service",
    component: () => import("/vercel/path0/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___in",
    path: "/in/terms-of-service",
    component: () => import("/vercel/path0/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "testimonials-slug___us",
    path: "/testimonials/:slug()",
    component: () => import("/vercel/path0/pages/testimonials/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "testimonials-slug___in",
    path: "/in/testimonials/:slug()",
    component: () => import("/vercel/path0/pages/testimonials/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "testimonials___us",
    path: "/testimonials",
    component: () => import("/vercel/path0/pages/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "testimonials___in",
    path: "/in/testimonials",
    component: () => import("/vercel/path0/pages/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "toi-et-moi-ring___us",
    path: "/toi-et-moi-engagement-rings",
    meta: indexqQwoJeOhndMeta || {},
    component: () => import("/vercel/path0/pages/toi-et-moi-engagement-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "toi-et-moi-ring___in",
    path: "/in/toi-et-moi-engagement-rings",
    meta: indexqQwoJeOhndMeta || {},
    component: () => import("/vercel/path0/pages/toi-et-moi-engagement-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "wedding-rings-old___us",
    path: "/wedding-rings-old",
    component: () => import("/vercel/path0/pages/wedding-rings-old.vue").then(m => m.default || m)
  },
  {
    name: "wedding-rings-old___in",
    path: "/in/wedding-rings-old",
    component: () => import("/vercel/path0/pages/wedding-rings-old.vue").then(m => m.default || m)
  },
  {
    name: "shop-anniversary___us",
    path: "/wedding-rings/anniversary-rings",
    meta: anniversary_45rings1x6wNWHSM4Meta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/anniversary-rings.vue").then(m => m.default || m)
  },
  {
    name: "shop-anniversary___in",
    path: "/in/wedding-rings/anniversary-rings",
    meta: anniversary_45rings1x6wNWHSM4Meta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/anniversary-rings.vue").then(m => m.default || m)
  },
  {
    name: "shop-couple-rings___us",
    path: "/wedding-rings/couple-rings",
    meta: couple_45ringsSzdOwO2PhHMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/couple-rings.vue").then(m => m.default || m)
  },
  {
    name: "shop-couple-rings___in",
    path: "/in/wedding-rings/couple-rings",
    meta: couple_45ringsSzdOwO2PhHMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/couple-rings.vue").then(m => m.default || m)
  },
  {
    name: "shop-eternity___us",
    path: "/wedding-rings/eternity-rings",
    meta: eternity_45ringsZmRqvaILBBMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/eternity-rings.vue").then(m => m.default || m)
  },
  {
    name: "shop-eternity___in",
    path: "/in/wedding-rings/eternity-rings",
    meta: eternity_45ringsZmRqvaILBBMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/eternity-rings.vue").then(m => m.default || m)
  },
  {
    name: "wedding-rings___us",
    path: "/wedding-rings",
    meta: indexRBH6TSDFabMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "wedding-rings___in",
    path: "/in/wedding-rings",
    meta: indexRBH6TSDFabMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-engagement-rings___us",
    path: "/wedding-rings/jewelry-engagement-rings",
    meta: jewelry_45engagement_45ringsCbRHgrj1GyMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/jewelry-engagement-rings.vue").then(m => m.default || m)
  },
  {
    name: "shop-engagement-rings___in",
    path: "/in/wedding-rings/jewelry-engagement-rings",
    meta: jewelry_45engagement_45ringsCbRHgrj1GyMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/jewelry-engagement-rings.vue").then(m => m.default || m)
  },
  {
    name: "shop-womens-wedding-rings___us",
    path: "/wedding-rings/womens-wedding-rings",
    meta: womens_45wedding_45ringsb8KFpPKxkKMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/womens-wedding-rings.vue").then(m => m.default || m)
  },
  {
    name: "shop-womens-wedding-rings___in",
    path: "/in/wedding-rings/womens-wedding-rings",
    meta: womens_45wedding_45ringsb8KFpPKxkKMeta || {},
    component: () => import("/vercel/path0/pages/wedding-rings/womens-wedding-rings.vue").then(m => m.default || m)
  }
]