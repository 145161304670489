import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {

    let userCountry = "US"  
   
    if(nuxtApp.$i18n && nuxtApp.$i18n.locale.value === 'in' ){
        userCountry = 'IN'
    }

    //set gloabal variable for payment 
    const country = {
        //for alternate tag start
        pathSegment: '/in',
        language: 'en-IN',
        currency_sign: 'USD',
        //for alternate tag end

        location: 'US',
        is_india: false,
        full_country_list: true,

        currency_usd: true,

        paymentFlag : { 
            credit_card: true,
            affirm: true,
            paypal: true, 
            paylater: true, 
            telephone: true, 
            ach: true, 
            splitit: true, 
            bankwire: true, 
            juspay: false ,
            afterpay: true
        },
        affirm : true,
        accrue : true,
        evertreen : true,
        appraisal : true,
        payment_banner: true,
        office_appointment: true,

        mobile: '+1 646 9564572',
        email: "customerservice@friendlydiamonds.com",

        dollar_icon: true,

        estimate_msg : 'Estimated date of shipment:',

        trustpilot: true,
        bbb: true,
        footer_pay_icons: true,

        coupon: true,
        returnPeriod: 30,
        expedite: false,
        addressLine: 'Please provide an address where someone 18 or older can accept and sign for the delivery. Unfortunately, we are unable to ship to P.O. boxes, APOs, and FPOs.',
        instagramHandle:'https://www.instagram.com/friendly.diamonds',

        free_product: true
    }

//enable/disable payment gateways as per current country
    if( userCountry === 'IN' ){

        //for alternate tag start
        country.pathSegment = '',
        country.language = 'en-US',
        country.currency_sign = 'INR',
        //for alternate tag end

        country.location = 'IN',

        country.is_india = true,
        country.full_country_list = false,

        country.currency_usd = false,
        
        country.paymentFlag.credit_card = false
        country.paymentFlag.affirm = false
        country.paymentFlag.paypal = false
        country.paymentFlag.paylater = false
        country.paymentFlag.telephone = true
        country.paymentFlag.ach = false
        country.paymentFlag.splitit = false
        country.paymentFlag.bankwire = false
        country.paymentFlag.juspay = true
        country.paymentFlag.afterpay = false

        country.affirm = false
        country.accrue = false
        country.evertreen = false
        country.appraisal = false
        country.payment_banner = false
        country.office_appointment = false

        country.mobile =  "+91 9920987654",
        country.email = "india@friendlydiamonds.com"

        country.dollar_icon = false

        country.estimate_msg = 'Estimated date of delivery:'

        country.trustpilot = false
        country.bbb = false
        country.footer_pay_icons = false

        country.returnPeriod = 15,
        country.coupon = true
        country.expedite = false
        country.addressLine = 'Please provide an address where someone 18 or older can accept and sign for the delivery.'
        country.instagramHandle = 'https://www.instagram.com/friendlydiamondsindia/'
        

        country.free_product = false
    }

    nuxtApp.provide('country', country);

});
