<template>
    <div class="sub_menu_parent">
        <NuxtLink class="for_desktop_mod hover_cta" :to="localePath('/lab-diamonds')">lab diamonds </NuxtLink>
        <div class="for_mobile_mod" @click="toggleMenu()">
            <div class="link_with_arrow">
                <span>lab diamonds</span>
                <SvgHeaderSvg v-if="arrow" :type="'down'" />
                <SvgHeaderSvg v-else :type="'up'" />
            </div>
        </div>
        <div class="main_menu_box" :class="{ 'open_for_desktop' : hoverFlag === 'diamond', 'open_for_mobile': openMainMenu }">
          <!-- ssr-only :hydratable="true" :hydrate-on-visible="true"  :class="{ 'open_for_desktop' : hoverFlag === 'diamond', 'open_for_mobile': openMainMenu }"-->
            <div class="inner_menu">
                <div class="landing_link inner_menu_box for_mobile_mod">
                    <NuxtLink :to="localePath('/lab-diamonds')">lab diamonds </NuxtLink>
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Loose Diamonds</h3>
                        <div class="menu_ordered">
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'round-cut-diamond' } })">
                                    <SvgShapeSvg :type="'round'" />&nbsp; Round
                                </NuxtLink>
                           
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'oval-cut-diamond' } })">
                                    <SvgShapeSvg :type="'oval'" />&nbsp; Oval
                                </NuxtLink>
                           
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'pear-cut-diamond' } })">
                                    <SvgShapeSvg :type="'pear'" />&nbsp; Pear
                                </NuxtLink>
                          
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'cushion-cut-diamond' } })">
                                    <SvgShapeSvg :type="'cushion'" />&nbsp; Cushion
                                </NuxtLink>
                           
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'princess-cut-diamond' } })">
                                    <SvgShapeSvg :type="'princess'" />&nbsp; Princess
                                </NuxtLink>
                         
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'radiant-cut-diamond' } })">
                                    <SvgShapeSvg :type="'radiant'" />&nbsp; Radiant
                                </NuxtLink>
                           
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'emerald-cut-diamond' } })">
                                    <SvgShapeSvg :type="'emerald'" />&nbsp; Emerald
                                </NuxtLink>
                           
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'heart-cut-diamond' } })">
                                    <SvgShapeSvg :type="'heart'" />&nbsp; Heart
                                </NuxtLink>
                          
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'asscher-cut-diamond' } })">
                                    <SvgShapeSvg :type="'asscher'" />&nbsp; Asscher
                                </NuxtLink>
                          
                                <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'marquise-cut-diamond' } })">
                                    <SvgShapeSvg :type="'marquise'" />&nbsp; Marquise
                                </NuxtLink>
                                <NuxtLink class="view_all_cta" :to="localePath({ name:'loose-listing' })">View All</NuxtLink> 

                                <!-- <NuxtLink :to="localePath({ name:'loose-listing' })">
                                    <SvgShapeSvg :type="'pair'" />&nbsp; Shop Matching Pair
                                </NuxtLink> -->
                           
                        </div>
                    </div>
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Popular  Searches </h3>
                        <div class="menu_ordered menu_ordered_noicon">
                            <!-- <NuxtLink :to="localePath('/')">Block-chain enabled diamonds  </NuxtLink> -->
                            <!-- <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'quick-shipped' } })">Quick Ship Diamonds  </NuxtLink> -->
                            <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'gia-certified' } })">GIA-certified diamonds </NuxtLink>
                            <NuxtLink :to="localePath({ name:'loose-listing', params:{ start_with: 'igi-certified' } })">IGI-certified diamonds </NuxtLink>
                        </div>
                    </div>
                    <div class="menu_card">
                        <h3>Create Your Own </h3>
                        <div class="menu_ordered">
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})"><SvgJewelrySvg :type="'cyor'" />&nbsp;create your own ring</NuxtLink>
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-earring'})"><SvgJewelrySvg :type="'cyoe'" />&nbsp;create your own earring</NuxtLink>
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-pendant'})"><SvgJewelrySvg :type="'cyop'" />&nbsp;create your own pendant</NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Services & Values</h3>
                        <div class="menu_ordered menu_ordered_noicon">
                            <NuxtLink :to="localePath('/customer-service/life-time-warranty')">Lifetime Warranty </NuxtLink>
                            <NuxtLink :to="localePath('/customer-service/return-policy')">{{ $country.returnPeriod }} Day Returns </NuxtLink>
                            <NuxtLink :to="localePath('/customer-service/payment-options')">Payment Options  </NuxtLink>
                        </div>
                    </div>
                    <div class="menu_card menu_card_no_border">
                        <h3>Diamond Education</h3>
                        <div class="menu_ordered menu_ordered_noicon">
                            <NuxtLink :to="localePath('/4-cs-diamond')">4 C's of a lab diamond </NuxtLink>
                            <NuxtLink :to="localePath('/ethical-and-conflict-free-diamonds')">Ethical & Conflict Free Diamonds </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="inner_menu_box inner_menu_four inner_menu_four_mod">
                    <div class="menu_card">
                        <div class="main_menu_image">
                            <picture>
                            <source width="249" height="304" type="image/webp" srcset="~/assets/header/menu_loose.webp">
                            <img loading="lazy" width="249" height="304" type="image/jpeg" src="~/assets/header/menu_loose.jpg" alt="Wedding Rings">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const { $country } = useNuxtApp()
    const props = defineProps([
      'openOverlay',
      'hoverFlag',
      'locale'
    ]);
    
    const hideMenu = ref(false);
    const openMainMenu = ref(false);
    const arrow = ref(true);
    
    const route = useRoute();
    const emit = defineEmits(['hideMenuInDesktop'])
    
    
    watch(route, (to, from) => {
      hideMenu.value = true;
      setTimeout(() => {
        hideMenu.value = false;
      }, 500);
      emit('hideMenuInDesktop');
    });
    
    watch(() => props.openOverlay, (newVal, oldVal) => {
      if (!oldVal) {
        arrow.value = true;
        openMainMenu.value = false;
      }
    });
    
    const toggleMenu = () => {
      arrow.value = !arrow.value;
      openMainMenu.value = !openMainMenu.value;
    };
    
</script>

<style scoped>

.landing_link .inner_menu_box{
    padding: 10px 0 !important;
} 
.main_menu_block ul li a {
    color: #303030;
    font-size: 16px;
    line-height: 1.25;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
}
.inner_menu_box .menu_card{
    border-top: none;
}
.menu_ordered{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
</style>
