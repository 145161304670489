// middleware/redirects.ts


export default defineNuxtRouteMiddleware((to, from) => {
  // Redirect based on the current route path
  if (['/eternity-rings', '/eternity-rings/'].includes(to.path)) {
    return navigateTo('/wedding-rings/eternity-rings');
  }
  if (['/couple-rings', '/couple-rings/'].includes(to.path)) {
    return navigateTo('/wedding-rings/couple-rings');
  }
  if (['/stud-earrings', '/stud-earrings/'].includes(to.path)) {
    return navigateTo('/fine-jewelry/stud-earrings');
  }
  if (['/hoop-earrings', '/hoop-earrings/'].includes(to.path)) {
    return navigateTo('/fine-jewelry/hoop-earrings');
  }
  if (['/tennis-necklace', '/tennis-necklace/'].includes(to.path)) {
    return navigateTo('/fine-jewelry/tennis-necklaces');
  }
  if (['/solitaire-necklace', '/solitaire-necklace/'].includes(to.path)) {
    return navigateTo('/fine-jewelry/solitaire-necklaces');
  }
  if (['/tennis-bracelet', '/tennis-bracelet/'].includes(to.path)) {
    return navigateTo('/fine-jewelry/tennis-bracelets');
  }
  if (['/womens-wedding-rings', '/womens-wedding-rings/'].includes(to.path)) {
    return navigateTo('/wedding-rings/womens-wedding-rings');
  }
  if (['/anniversary-rings', '/anniversary-rings/'].includes(to.path)) {
    return navigateTo('/wedding-rings/anniversary-rings');
  }
  if (['/blog/category', '/blog/category/'].includes(to.path)) {
    return navigateTo('/blog');
  }
  if (['/in/blog/category', '/in/blog/category/'].includes(to.path)) {
    return navigateTo('/in/blog');
  }
  if (['/ring-settings/10kt-white-gold-crowning-glory-diamond-ring-afdr1100-3001', '/ring-settings/10kt-white-gold-crowning-glory-diamond-ring-afdr1100-3001/'].includes(to.path)) {
    return navigateTo('/ring-settings');
  }
  if (['/wedding-band', '/wedding-band/'].includes(to.path)) {
    return navigateTo('/wedding-rings/womens-wedding-rings');
  }
  
  // Blog category old to new
  if (['/blog/new-trends', '/blog/new-trends/'].includes(to.path)) {
    return navigateTo('/blog/category/new-trends');
  }
  if (['/blog/category', '/blog/category/'].includes(to.path)) {
    return navigateTo('/blog');
  }
  if (['/blog/gift-guide', '/blog/gift-guide/'].includes(to.path)) {
    return navigateTo('/blog/category/gift-ideas');
  }
  if (['/blog/know-diamonds', '/blog/know-diamonds/'].includes(to.path)) {
    return navigateTo('/blog/category/diamond-guide');
  }
  if (['/blog/rings-and-wedding', '/blog/rings-and-wedding/'].includes(to.path)) { 
    return navigateTo('/blog/category/all-about-rings');
  }
   
  if (['/index.php', '/index.php/', '/index', '/index/'].includes(to.path)) {
    return navigateTo('/');
  }
  
  // Additional redirects
  if ('/in/fine-jewelry' === to.path) {
    return navigateTo('/in/fine-jewellery');
  }
  if ('/in/fine-jewelry/stud-earrings' === to.path) {
    return navigateTo('/in/fine-jewellery/stud-earrings');
  }
  if ('/in/fine-jewelry/hoop-earrings' === to.path) {
    return navigateTo('/in/fine-jewellery/hoop-earrings');
  }
  if ('/in/fine-jewelry/tennis-necklaces' === to.path) {
    return navigateTo('/in/fine-jewellery/tennis-necklaces');
  }
  if ('/in/fine-jewelry/solitaire-necklaces' === to.path) {
    return navigateTo('/in/fine-jewellery/solitaire-necklaces');
  }
  if ('/in/fine-jewelry/tennis-bracelets' === to.path) {
    return navigateTo('/in/fine-jewellery/tennis-bracelets');
  }
  if ('/in/diamond-jewelry' === to.path) {
    return navigateTo('/in/diamond-jewellery');
  }
  if ('/in/customized-jewelry' === to.path) {
    return navigateTo('/in/customise-jewellery');
  }
  
  if ('/fine-jewellery' === to.path) {
    return navigateTo('/fine-jewelry');
  }
  if ('/fine-jewellery/stud-earrings' === to.path) {
    return navigateTo('/fine-jewelry/stud-earrings');
  }
  if ('/fine-jewellery/hoop-earrings' === to.path) {
    return navigateTo('/fine-jewelry/hoop-earrings');
  }
  if ('/fine-jewellery/tennis-necklaces' === to.path) {
    return navigateTo('/fine-jewelry/tennis-necklaces');
  }
  if ('/fine-jewellery/solitaire-necklaces' === to.path) {
    return navigateTo('/fine-jewelry/solitaire-necklaces');
  }
  if ('/fine-jewellery/tennis-bracelets' === to.path) {
    return navigateTo('/fine-jewelry/tennis-bracelets');
  }
  if ('/diamond-jewellery' === to.path) {
    return navigateTo('/diamond-jewelry');
  }
  if ('/customise-jewellery' === to.path) {
    return navigateTo('/customized-jewelry');
  }
});
