export default defineNuxtPlugin((nuxtApp) => {

    const setAnalytics = async () => {
        let currencyCode;

        // Wait for the app to be ready
        nuxtApp.hook('app:mounted', () => {
        
            //------------- load google tag manager script -------------//
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
                });
                const f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l !== 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', useRuntimeConfig().public.gtagKey);


            if( nuxtApp.$country.location == 'IN' ){
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', 1582392115957624);
                fbq('track', 'PageView');

            }


            currencyCode = nuxtApp.$country.currency_sign;

        });


        function trigerfb(eventName, data){
            
            try {
                
                // $fetch(useCountryPath('/send-facebook-meta-event'), {
                //     method:'POST',
                //     body: {eventName,data}
                // })
                return
                if (typeof fbq === "function") { 
                    // call api
                    fbq('track', eventName, data)
                    console.log('tag triggered',  eventName, data)        
                }
            } catch (error) {
                
                console.log('error on fbq event', error)
            }
        }
    
        //------------- Register GTAG -------------//
        nuxtApp.provide('registerGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            if (data.status === 'load') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "start step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": true
                });

                trigerfb('account creation',{
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "start step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": true
                })
            }
            if (data.status === 'submit') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "submit step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('account creation',{
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "submit step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
                
            }
            if (data.status === 'complete') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "complete step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('account creation',{
                    "event": "genEvent",
                    "eventCategory": "funnels",
                    "eventAction": "complete step 1",
                    "eventLabel": "account creation",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })


                window.dataLayer.push({
                    'event':'form1',
                    'enhanced_conversion_data': {
                    "email": data.data.email,
                    "phone_number": ''
                    }
                })
            }
        })
        //------------- Social media GTAG -------------//
        nuxtApp.provide('socialGtag', (status) => { 
            window.dataLayer = window.dataLayer || [];
        
            if (status === 'facebook') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.facebook.com/FriendlyDiamonds",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('https://www.facebook.com/FriendlyDiamonds',{
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.facebook.com/FriendlyDiamonds",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
            }
            if (status === 'twitter') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://twitter.com/FriendlyDiam",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('https://twitter.com/FriendlyDiam',{
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://twitter.com/FriendlyDiam",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
            }
            if (status === 'instagram') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.instagram.com/friendly.diamonds/",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });

                trigerfb('https://www.instagram.com/friendly.diamonds/',{
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.instagram.com/friendly.diamonds/",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
            }
            if (status === 'linkedin') {
                window.dataLayer.push({
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.linkedin.com/company/friendly-diamonds",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                });
                trigerfb('https://www.linkedin.com/company/friendly-diamonds',{
                    "event": "genEvent",
                    "eventCategory": "interactions",
                    "eventAction": "click",
                    "eventLabel": "https://www.linkedin.com/company/friendly-diamonds",
                    "eventValue": undefined,
                    "eventNonInteraction": false
                })
            }
        })

        //------------- newsletter GTAG -------------//
        nuxtApp.provide('newsletterGtag', () => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": "newsletter subscription", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false // DO NOT CHANGE
            });

            trigerfb('newsletter subscription',{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": "newsletter subscription", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false // DO NOT CHANGE
            })
        
        })

        //------------- cyo choose setting GTAG -------------//
        nuxtApp.provide('choosesettingGtag', (moduleSubType) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 1", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb( moduleSubType +" creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 1", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })
        
        })

        //------------- cyo select setting GTAG -------------//
        nuxtApp.provide('selectsettingGtag', (moduleSubType) => { 
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb(moduleSubType + " creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })
            
        })

        //------------- cyo choose diamond GTAG -------------//
        nuxtApp.provide('choosediamondGtag', (moduleSubType) => { 
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 2", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb(moduleSubType + " creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 2", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })
        
        })

        //------------- cyo select diamond GTAG -------------//
        nuxtApp.provide('selectdiamondGtag', (moduleSubType) => { 
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 2", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false // DO NOT CHANGE
            });

            trigerfb(moduleSubType + " creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 2", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false // DO NOT CHANGE
            })
            
        })

        //------------- cyo complete page  GTAG -------------//
        nuxtApp.provide('loadcompleteGtag', (moduleSubType) => { 
        
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 3", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb(moduleSubType + " creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 3", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })
            
        })

        //------------- cyo add to cart  GTAG -------------//
        nuxtApp.provide('cyotoCartGtag', (moduleSubType, data) => { 
            
            let products = [{
                "id": data.setting.id,
                "brand": "friendly diamonds",
                "name": data.setting.activeTitle,
                "variant": data.setting.activeMetal,
                "price": data.setting.activePrice,
                "quantity": 1
            }]
            if (moduleSubType != 'earring') {
                products.push({
                    "id": data.diamond.id,
                    "brand": "friendly diamonds",
                    "name": data.diamond.title,
                    "variant": `${data.diamond.cut_grade} Cut, ${data.diamond.color} color ,  ${data.diamond.clarity} Clarity`,
                    "price": parseFloat(data.diamond.price),
                    "quantity": 1
                })
                if(data.merchant_id){
                    products.push({
                        "id": data.merchant_id,
                        "brand": "friendly diamonds",
                        "name": data.setting.activeTitle,
                        "variant": data.setting.activeMetal,
                        "price": parseFloat(data.setting.activePrice) + parseFloat(data.diamond.price),
                        "quantity": 1
                    })
                }
                
            }
            if (moduleSubType === 'earring') {
                products.push({
                    "id": data.diamond.id,
                    "brand": "friendly diamonds",
                    "name": `${data.diamond.weight_one} Total Carat ${data.diamond.shape_one} Lab Grown Diamond`,
                    "variant": `${data.diamond.cut_grade_one} Cut, ${data.diamond.color_one} color ,  ${data.diamond.clarity_one} Clarity`,
                    "price": parseFloat(data.diamond.price_one),
                    "quantity": 1
                }, {
                    "id": data.diamond.id,
                    "brand": "friendly diamonds",
                    "name": `${data.diamond.weight_two} Total Carat ${data.diamond.shape_two} Lab Grown Diamond`,
                    "variant": `${data.diamond.cut_grade_two} Cut, ${data.diamond.color_two} color ,  ${data.diamond.clarity_two} Clarity`,
                    "price": parseFloat(data.diamond.price_two),
                    "quantity": 1
                })
            }
            if (data.appraisal === 1) {
                products.push({
                    "id": 999999999,
                    "brand": "friendly diamonds",
                    "name": "appraisal",
                    "variant": "",
                    "price": 50,
                    "quantity": 1
                })
            }
            //submit step 3
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 3", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false
            });

            trigerfb(moduleSubType + " creation",{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 3", // DO NOT CHANGE
                "eventLabel": moduleSubType + " creation", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false
            })

        
            //add to cart push prodcts to datalayer 
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.total),
                    "add": {
                        "actionField": {
                            "list": moduleSubType + " complete page"
                        },
                        "products": products
                    }
                }
            });

            trigerfb('sub-brand',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.total),
                    "add": {
                        "actionField": {
                            "list": moduleSubType + " complete page"
                        },
                        "products": products
                    }
                }
            })
        })
    
        //------------- loose diamond impression GTAG -------------//
        nuxtApp.provide('looseImpressionGtag', (data) => { 
            let listing = []
            data.filter((item) => { 
                if (item.length > 0) {
                    listing.push({
                        "id": item.id,
                        "brand": "friendly diamonds",
                        "name": item.title,
                        "variant": `${item.cut_grade} Cut, ${item.color} Color, ${item.clarity} Clarity,  LotNo. ${item.lot}`,
                        "category": "cyo/loose",
                        "price": parseFloat(item.price),
                        "list": "loose diamonds",
                        "position": 1
                    })
                }
            })

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression view", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "impressions": listing
                }
            });

            trigerfb('loose diamonds',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression view", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "impressions": listing
                }
            })
        
        })

        //------------- view loose diamond GTAG -------------//
        nuxtApp.provide('looseViewGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression click", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "click": {
                        "actionField": {
                            "list": "loose diamonds"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.title,
                            "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                            "category": "cyo/loose",
                            "price": parseFloat(data.price),
                            "list": "loose diamonds",
                            "position": 1
                        }]
                    }
                }
            });

            trigerfb('loose diamonds',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression click", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "click": {
                        "actionField": {
                            "list": "loose diamonds"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.title,
                            "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                            "category": "cyo/loose",
                            "price": parseFloat(data.price),
                            "list": "loose diamonds",
                            "position": 1
                        }]
                    }
                }
            })
            
        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('loosetoCartGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "click": {
                        "actionField": {
                            "list": "loose diamonds"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.title,
                            "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                            "category": "cyo/loose",
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            });

            trigerfb('loose diamonds',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "loose diamonds", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "click": {
                        "actionField": {
                            "list": "loose diamonds"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.title,
                            "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                            "category": "cyo/loose",
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            })
        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentInitiateGtag', () => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb('virtual appointment',{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "start step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })

        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentCreationGtag', () => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb('virtual appointment',{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "submit step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })

        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentSucessGtag', () => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "complete step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            });

            trigerfb('virtual appointment',{
                "event": "genEvent", // DO NOT CHANGE
                "eventCategory": "funnels", // DO NOT CHANGE
                "eventAction": "complete step 1", // DO NOT CHANGE
                "eventLabel": "virtual appointment", // DO NOT CHANGE
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true // DO NOT CHANGE
            })

        })

        //-------------  remove from cart GTAG -------------//
        nuxtApp.provide('removefromCartGtag', (item) => { 
            let products = []
            products.push(item)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "remove from cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "remove": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": products
                    }
                }
            });

            trigerfb('sub-brand',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "remove from cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "remove": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": products
                    }
                }
            })
        
        })

        //-------------  checkout address GTAG -------------//
        nuxtApp.provide('addressGtag', (data) => { 
            let products = []
            if (data.items.length) {
                data.items.filter((item) => { 
                    products.push(item.gtag)
                })
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 1", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": data.total_label,
                    "checkout": {
                        "actionField": {
                            "step": 1
                        },
                        "products": products
                    }
                }
            });

            trigerfb('step 1',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 1", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": data.total_label,
                    "checkout": {
                        "actionField": {
                            "step": 1
                        },
                        "products": products
                    }
                }
            })
        
        })

        nuxtApp.provide('submitAddressGtag', (data) => { 
        
            window.dataLayer.push({
                'event':'form1',
                'enhanced_conversion_data': {
                "email": data.email,
                "phone_number": data.phone.replace(/\s/g, '')
                }
            })
        })

        //-------------  checkout payment GTAG -------------//
        nuxtApp.provide('paymentGtag', (data) => { 
            let products = []
            if (data.items.length) {
                data.items.filter((item) => { 
                    products.push(item.gtag)
                })
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 2", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": data.total_label,
                    "checkout": {
                        "actionField": {
                            "step": 2
                        },
                        "products": products
                    }
                }
            });

            trigerfb('step 2',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 2", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": data.total_label,
                    "checkout": {
                        "actionField": {
                            "step": 2
                        },
                        "products": products
                    }
                }
            })
        })

        //-------------  checkout payment type GTAG -------------//
        nuxtApp.provide('paymentTypeGtag', (pay_type) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 3", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "checkout_option": {
                        "actionField": {
                            "step": 3,
                            "option": pay_type
                        }
                    }
                }
            });

            trigerfb('step 3',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "checkout", // DO NOT CHANGE
                "eventLabel": "step 3", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "checkout_option": {
                        "actionField": {
                            "step": 3,
                            "option": pay_type
                        }
                    }
                }
            })
        })

        //-------------  checkout payment success GTAG -------------//
        nuxtApp.provide('paymentSuccessGtag', (data, trans_id) => { 
            let products = []
            if (data.items.length) {
                data.items.filter((item) => { 
                    products.push(item.gtag)
                })
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "purchase", // DO NOT CHANGE
                "eventLabel": "webshop", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "purchase": {
                        "actionField": {
                            "id": trans_id,
                            "affiliation": "webshop",
                            "revenue": data.total_label,
                            "tax": data.tax_label,
                            "shipping": 0.00,
                            "coupon": undefined,
                            "commission": 'default'
                        },
                        "products": products
                    }
                }
            });

            trigerfb('webshop',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "purchase", // DO NOT CHANGE
                "eventLabel": "webshop", // SAME NUMBER AS STEP
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "purchase": {
                        "actionField": {
                            "id": trans_id,
                            "affiliation": "webshop",
                            "revenue": data.total_label,
                            "tax": data.tax_label,
                            "shipping": 0.00,
                            "coupon": undefined,
                            "commission": 'default'
                        },
                        "products": products
                    }
                }
            })
        })

        nuxtApp.provide('jewelryImpressionGtag', (data) => { 

            if(! (data && data.length) ){

                return
            }

            let listing = []
            data.filter((item) => { 

                if( !(item.metals && item.metals.length > 0) ){

                    return false
                }

                item.metals.find((list) => { 
                    if(list.selected === 1){
                        listing.push({
                            "id": item.id,
                            "brand": "friendly diamonds",
                            "name": item.product_name,
                            "variant": list.name,
                            "category": "jewelry/" + item.sub_type,
                            "price": parseFloat(list.price),
                            "list": item.sub_type,
                            "position": 1
                        })
                    }
                })
            })
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression view", // DO NOT CHANGE
                "eventLabel": "category page", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "impressions": listing
                }
            });

            trigerfb('category page',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression view", // DO NOT CHANGE
                "eventLabel": "category page", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "impressions": listing
                }
            })
        })

        nuxtApp.provide('selectJewelryGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression click", // DO NOT CHANGE
                "eventLabel": "category page", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "click": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.carats.find((item) => item.selected === 1).id,
                            "brand": "friendly diamonds",
                            "name": data.data.title,
                            "variant": "",
                            "category": "jewelry/" + data.sub_module,
                            "price": parseFloat(data.carats.find((item) => item.selected === 1).price),
                            "list": "category page",
                            "position": 1
                        }]
                    }
                }
            });

            trigerfb('category page',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product impression click", // DO NOT CHANGE
                "eventLabel": "category page", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "click": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.carats.id,
                            "brand": "friendly diamonds",
                            "name": data.data.title,
                            "variant": "",
                            "category": "jewelry/" + data.sub_module,
                            "price": parseFloat(data.carats.price),
                            "list": "category page",
                            "position": 1
                        }]
                    }
                }
            })
            
        })

        nuxtApp.provide('jewelryDetailGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.carat.id,
                            "brand": "friendly diamonds",
                            "name": data.carat.product_name,
                            "variant": "",
                            "category": "jewelry/" + data.metal.sub_type,
                            "price": parseFloat(data.carat.price)
                        }]
                    }
                }
            });

            trigerfb('sub-brand',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.carat.id,
                            "brand": "friendly diamonds",
                            "name": data.carat.product_name,
                            "variant": "",
                            "category": "jewelry/" + data.metal.sub_type,
                            "price": parseFloat(data.carat.price)
                        }]
                    }
                }
            })
        })

        nuxtApp.provide('jewelryToCartGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.carat.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.carat.id,
                            "brand": "friendly diamonds",
                            "name": data.metal.product_name,
                            "variant": "",
                            "category": "jewelry/" + data.metal.sub_type,
                            "price": parseFloat(data.carat.price),
                            "quantity": 1
                        }]
                    }
                }
            });

            trigerfb('sub-brand',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.carat.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.carat.id,
                            "brand": "friendly diamonds",
                            "name": data.metal.product_name,
                            "variant": "",
                            "category": "jewelry/" + data.metal.sub_type,
                            "price": parseFloat(data.carat.price),
                            "quantity": 1
                        }]
                    }
                }
            })
        })

        nuxtApp.provide('presetDetailGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": "preset-engagement-ring/" + data.sub_type,
                            "price": parseFloat(data.price)
                        }]
                    }
                }
            });

            trigerfb('sub-brand',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": "preset-engagement-ring/" + data.sub_type,
                            "price": parseFloat(data.price)
                        }]
                    }
                }
            })
        })

        nuxtApp.provide('presetToCartGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": "preset-engagement-ring/" + data.sub_type,
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            });

            trigerfb('sub-brand',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": "preset-engagement-ring/" + data.sub_type,
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            })
        })

        nuxtApp.provide('bandDetailGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": data.sub_type,
                            "price": parseFloat(data.price)
                        }]
                    }
                }
            });

            trigerfb('sub-brand',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "product detail view", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": true, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "detail": {
                        "actionField": {
                            "list": "product detail page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": data.sub_type,
                            "price": parseFloat(data.price)
                        }]
                    }
                }
            })
        })

        nuxtApp.provide('bandToCartGtag', (data) => { 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": data.sub_type,
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            });

            trigerfb('sub-brand',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "add to cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "value": parseFloat(data.price),
                    "add": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": [{
                            "id": data.id,
                            "brand": "friendly diamonds",
                            "name": data.product_name,
                            "variant": "",
                            "category": data.sub_type,
                            "price": parseFloat(data.price),
                            "quantity": 1
                        }]
                    }
                }
            })
        })

    };
  
    setAnalytics().catch(console.error);
  
  });
  