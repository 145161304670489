import { defineNuxtRouteMiddleware, useNuxtApp, useRequestHeaders, useCookie } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {

  const defaultStore = 'in'

  const {public: config} = useRuntimeConfig()

  // test commit
  // return
  return
  
  if( config.enableLocalization !== 'true' ){

    return
  }
  // console.log( 'path starts with',to.path.startsWith('/_nuxt')   )
  if( to.path.startsWith('/_nuxt') ){

    return
  }

  if( import.meta.server && !to.path.startsWith('/api') ){

    const disableLocaleFlag = to.query.disable_localization
    
    if (disableLocaleFlag) {

      const cookie = useCookie('disable_localization', {
        path: '/',
        httpOnly: true,
        sameSite: 'lax',
      })

      cookie.value = 'true'
    }


    // Check if 'disable_localization' is set in cookie
    const checkIfLocaleDisabled = useCookie('disable_localization')
    if (checkIfLocaleDisabled.value == 'true' || disableLocaleFlag) {
      return
    }


    // Continue to localization if flag is not present
    let countryCode = useRequestHeaders(['cf-ipcountry']) ?  useRequestHeaders(['cf-ipcountry']) : ''

    if (!countryCode.value && defaultStore) {
      countryCode = defaultStore
    }

    const redirectionMap = {
      'in': '/in',
    };

    const targetPath = redirectionMap[countryCode];

    if (targetPath && !to.path.startsWith(targetPath)) {

      return navigateTo( to.path === '/' ? targetPath : targetPath + to.path, { redirectCode: 302 })
    }
  }

})