<template>
    <form @submit.prevent="Submit()">
        <div class="subscribe_pop">
            <input required maxlength="250" v-model="formData.email" type="email" placeholder="Your Email Id">
            <button type="submit">
                <SvgHeaderSvg :type="'right'" />
            </button>
        </div>
        <p class="success mb-0" v-if="success">{{ success }}</p>
        <p class="error mb-0" v-if="errorMessage.email">{{ errorMessage.email.join(" ") }}</p>
    </form>
</template>


<script setup>
    const { $newsletterGtag } = useNuxtApp()
    const formData = reactive({
    email: ''
    });
    const success = ref(false)
    const errorMessage= {}

    const Submit = async () => {
    try {
        const response = await $fetch(useCountryPath('/subscribe/from-footer'), {
            method: 'POST',
            body: formData
        })
        if(response){
            success.value = response.message;
            $newsletterGtag()
            // console.log(response, 'forgotpass 1');
            formData.email = '';
            setTimeout(() => {
                success.value = false;
            },2000);
        }
    } 
    catch (error) {
        errorMessage.value = error.response;
        // console.log(errorMessage.value, 'Error messages'); 
    }
}
</script>


<style scoped>
.svg-head {
    height: 12px;
    width: 12px;
}
p {
    font-size: 14px;
    padding: 5px 0;
    font-weight: 600;
    color: var(--green);
    text-transform: capitalize;
}
.error{
    color: rgb(255, 0, 0);
}
.subscribe_pop {
    border: 1px solid #000;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subscribe_pop input {
    border: 0;
    box-shadow: none;
    width: 100%;
}

.subscribe_pop input::placeholder {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.subscribe_pop button {
    background: transparent;
    border: 0;
    box-shadow: none;
}

.subscribe_pop input:focus-visible {
    border: 0;
    box-shadow: none;
    outline: none;
}
</style>
