export default defineNuxtPlugin(() => {

    const modalCleaup = async ()=>{

        const selectors = document.querySelectorAll('.modal.fade');

        let discardBackdrop = false
        if( selectors && selectors.length ){
            
            for (const modalId of selectors) {
                
                const modalInstance = bootstrap.Modal.getInstance(modalId);
                if (modalInstance) {
                    modalInstance.dispose();
                    discardBackdrop  = true
                }
            }


            if( discardBackdrop ){
                
                //Check if 'modal-open' class exists on the body and remove it if present
                if (document.body.classList.contains('modal-open')) {
                    document.body.classList.remove('modal-open');
                }
    
                // Check if any elements with the class 'modal-backdrop' exist and remove them
                const modalBackdrops = document.querySelectorAll('.modal-backdrop');
                if (modalBackdrops.length > 0) {
                    modalBackdrops.forEach(function(modalBackdrop) {
                        modalBackdrop.remove();
                    });
                }
            }
        }
    }
    
    addRouteMiddleware( async (to, from) => {

        if( import.meta.server ){

            return
        }

        await modalCleaup()
    })
})
  