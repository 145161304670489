import { defineNuxtPlugin } from '#app'

function getClientAppVersion() {
  return localStorage.getItem('APP_VERSION') ?? '0';
}

function setClientAppVersion(version) {
  localStorage.setItem('APP_VERSION', version);
}

function isLocalStorageAvailable() {
  const testKey = 'fd_r'; // Key used for testing localStorage availability

  try {
    // Try to set a test item in localStorage
    localStorage.setItem(testKey, testKey);
    // Try to remove the test item from localStorage
    localStorage.removeItem(testKey);
    // If both operations are successful, localStorage is available
    return true;
  } catch (e) {
    // If errors are thrown during either operation, localStorage is not available
    return false;
  }
}

export default defineNuxtPlugin(nuxtApp => {
  if (!isLocalStorageAvailable()) {
    console.log('LocalStorage is not available');
    return;
  }

  nuxtApp.hook('page:finish', async () => {
    try {
      const response = await fetch('/version.json');
      const { version: latestVersion } = await response.json();

      const clientStoredVersion = getClientAppVersion();

      // Check if localStorage item is set, i.e., first load
      if (clientStoredVersion === '0') {
        setClientAppVersion(latestVersion);
        return;
      }

      if (clientStoredVersion !== latestVersion) {
        window.location.reload(true);
        setClientAppVersion(latestVersion);
      }
    } catch (error) {
      console.error('Error fetching version:', error);
    }
  });
});
