<template>
    <!-- :class="{'sub_menu_parent': !isAuthenticated, 'sub_menu_parent_mod': isAuthenticated}" -->
    
        <div class="sub_menu_parent" v-if="loader">
            <NuxtLink class="for_desktop_mod hover_cta" :to="localePath('/about-us')">About </NuxtLink>
        </div>
      <div class="sub_menu_parent" v-else :class="{'sub_menu_parent': !isAuthenticated, 'sub_menu_parent_mod': isAuthenticated}" >
            <NuxtLink class="for_desktop_mod hover_cta" :to="localePath('/about-us')">About </NuxtLink>
            <div class="for_mobile_mod" @click="toggleMenu()">
                <div class="link_with_arrow">
                    <span>About</span>
                    <!-- <SvgHeaderSvg class="headdown"  :type="'down'" /> -->
                    <SvgHeaderSvg v-if="arrow" :type="'down'" />
                    <SvgHeaderSvg v-else :type="'up'" />
                </div>
            </div>
            <div class="main_menu_box" :class="{ 'open_for_desktop' : hoverFlag === 'about', 'open_for_mobile': openMainMenu }">
             
                <!-- ssr-only :hydratable="true" :hydrate-on-visible="true" -->
                <div class="inner_menu">
                    <div class="landing_link inner_menu_box for_mobile_mod">
                        <NuxtLink :to="localePath('/about-us')">About</NuxtLink>
                    </div>
                    <div class="inner_menu_box inner_menu_four">
                        <div class="menu_card">
                            <h3>About </h3>
                            <div class="menu_ordered menu_ordered_noicon">
                                <NuxtLink :to="localePath('/our-story')">Our Story </NuxtLink>
                                <NuxtLink :to="localePath('/ethical-and-conflict-free-diamonds')">Ethical & Conflict free Diamond</NuxtLink>
                                <NuxtLink :to="localePath('/friendly-diamond-difference')">The Friendly Diamonds Difference </NuxtLink>
                                <NuxtLink :to="localePath('/green-and-sustainable-gift')" v-if="$country.evertreen">Giving Back to Our Planet </NuxtLink>
                                <NuxtLink :to="localePath({ name: 'blog-listing' })">Blog</NuxtLink>
                                <NuxtLink :to="localePath('/testimonials')">Testimonials</NuxtLink>
                                <NuxtLink :to="localePath('/customer-service')">Services & Values</NuxtLink>
                                <NuxtLink :to="localePath('/press')">Press</NuxtLink>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="inner_menu_box inner_menu_four">
                        <div class="menu_card">
                            <h3>Contact</h3>
                            <div class="menu_ordered menu_ordered_noicon">
                                <NuxtLink :to="localePath('/contact-us')">Contact Us </NuxtLink>
                                <NuxtLink :to="localePath('/appointment')">Book an appointment</NuxtLink>
                            </div>
                        </div>
                    </div>
                    <div class="inner_menu_box inner_menu_four">
                        <div class="menu_card menu_card_no_border">
                            <h3>Education </h3>
                            <div class="menu_ordered menu_ordered_noicon">
                                <NuxtLink :to="localePath('/4-cs-diamond')">4 C's of a lab diamond </NuxtLink>
                                <NuxtLink :to="localePath('/engagement-ring-guide')">Engagement Ring Guide </NuxtLink>
                                <NuxtLink :to="localePath('/how-to-find-ring-size')">Ring Size Guide </NuxtLink>
                            </div>
                        </div>
                    </div>
                    <div class="inner_menu_box inner_menu_four inner_menu_four_mod">
                        <div class="menu_card">
                            <div class="main_menu_image">
                                <picture>
                                <source width="249" height="304" type="image/webp" srcset="~/assets/header/menu_about.webp">
                                <img loading="lazy" width="249" height="304" type="image/jpeg" src="~/assets/header/menu_about.jpg" alt="About us">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script setup>
    import setCsrfToken from '~/composables/csrf/setCsrfToken';


    const localePath = useLocalePath()
    const { $country } = useNuxtApp()
    const { login, logout, isAuthenticated  } = useSanctumAuth();

    async function loggedout(){
            await setCsrfToken()
            await logout()
    }

    const props = defineProps([
      'openOverlay',
      'hoverFlag',
      'locale'
    ]);

    const hideMenu = ref(false);
    const openMainMenu = ref(false);
    const arrow = ref(true);

    const loader = ref(true)

    const route = useRoute();
    const emit = defineEmits(['hideMenuInDesktop'])


    watch(route, (to, from) => {
      hideMenu.value = true;
      setTimeout(() => {
        hideMenu.value = false;
      }, 500);
      emit('hideMenuInDesktop');
    });

    watch(() => props.openOverlay, (newVal, oldVal) => {
      if (!oldVal) {
        arrow.value = true;
        openMainMenu.value = false;
      }
    });

    const toggleMenu = () => {
      arrow.value = !arrow.value;
      openMainMenu.value = !openMainMenu.value;
    };

    defineOptions({
      name: 'AboutMenu',
      serverCacheKey: props => `AboutMenu::${props.locale}`,
    });
    onMounted(() =>{
        loader.value = false
    })
</script>

<style scoped>
.landing_link .inner_menu_box{
    padding: 10px 0 !important;
} 
.main_menu_block ul li a {
    color: #303030;
    font-size: 16px;
    line-height: 1.25;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
}
.inner_menu_box .menu_card{
    border-top: none;
}
.menu_ordered{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
</style>