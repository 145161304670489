<template>
    <div class="sub_menu_parent">
       <NuxtLink class="for_desktop_mod hover_cta" :to="localePath('/wedding-rings')">Wedding</NuxtLink>
        <div class="for_mobile_mod" @click="toggleMenu()">
            <div class="link_with_arrow">
                <span>Wedding</span>
                <!-- <SvgHeaderSvg :type="'down'" /> -->
                <!-- <HeaderSvg v-if="arrow" :type="'down'" />
                <HeaderSvg v-else :type="'up'" /> -->
                <SvgHeaderSvg v-if="arrow" :type="'down'" />
                <SvgHeaderSvg v-else :type="'up'" />
            </div>
        </div>
        <div class="main_menu_box" :class="{ 'open_for_desktop' : hoverFlag === 'wedding', 'open_for_mobile': openMainMenu }">        
            <div class="inner_menu">
                <div class="landing_link inner_menu_box for_mobile_mod">
                    <NuxtLink :to="localePath('/wedding-rings')">Wedding</NuxtLink>
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Wedding Rings</h3>
                        <div class="menu_ordered">
                            <NuxtLink :to="localePath({ name:'shop-womens-wedding-rings' })"><SvgJewelrySvg :type="'women_wedding'" />&nbsp; Women's wedding rings </NuxtLink>
                            <NuxtLink :to="localePath({ name:'shop-eternity' })"><SvgJewelrySvg :type="'eternity'" />&nbsp; Eternity Rings </NuxtLink>
                            <NuxtLink :to="localePath({ name: 'shop-anniversary'})"><SvgJewelrySvg :type="'five_stone'" />&nbsp; Anniversary Rings </NuxtLink>
                            <NuxtLink :to="localePath({ name:'toi-et-moi-ring'})">
                                <SvgJewelrySvg :type="'toi-moi'" />&nbsp;Toi Et Moi Rings
                            </NuxtLink>
                            <NuxtLink :to="localePath({ name:'shop-couple-rings' })"><SvgJewelrySvg :type="'couple'" />&nbsp; Couple Rings  </NuxtLink>
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})">
                                <SvgJewelrySvg :type="'cyor'" />&nbsp; Create Your Own Ring
                            </NuxtLink>
                           
                        </div>
                    </div>
                    <!-- <div class="menu_card">
                        <h3>Anniversary Rings </h3>
                        <div class="menu_ordered">
                            <NuxtLink :to="localePath('/wedding-rings/anniversary-rings'"><JewelrySvg :type="'five_stone'" />&nbsp; Five Stone Rings </NuxtLink>
                            <NuxtLink :to="localePath('/')"><JewelrySvg  :type="'seven_stone'" />&nbsp; Seven Stone Rings </NuxtLink>
                        </div>
                    </div> -->
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Jewelry for the big day</h3>
                        <div class="menu_ordered">
                            <NuxtLink :to="localePath({ name: 'shop-earring'})"><SvgJewelrySvg :type="'stud'" />&nbsp; Diamond Studs </NuxtLink>
                            <NuxtLink :to="localePath({ name: 'shop-bracelet'})"><SvgJewelrySvg :type="'bracelet'" /> &nbsp;&nbsp;Tennis Bracelets</NuxtLink>
                            <NuxtLink :to="localePath({ name: 'shop-hoop'})"><SvgJewelrySvg :type="'hoop'" />&nbsp; Hoop Earrings </NuxtLink>
                            <NuxtLink :to="localePath({ name: 'shop-solitaire-necklace'})"><SvgJewelrySvg :type="'solitaire'" />&nbsp; Solitaire Necklaces</NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Bespoke {{ $t('customize') }}d {{ $t('jewelry') }}</h3>
                        <div class="menu_ordered menu_ordered_noicon">
                            <NuxtLink :to="localePath('/customized-jewelry')">{{ $t('customize') }} Your {{ $t('jewelry') }}</NuxtLink>
                        </div>
                    </div>
                   
                    <div class="menu_card menu_card_no_border">
                        <!-- <h3>blogs </h3> -->
                        <h3 class="">
                            <NuxtLink class="for_desktop_mod" :to="localePath('/blog')">Blogs</NuxtLink> 
                            <div class="for_mobile_mod">Blogs</div>
                        </h3>
                        <div v-if="!$country.is_india" class="menu_ordered menu_ordered_noicon">
                            <NuxtLink :to="localePath('/blog/celebrity-wedding-rings')">Best Celebrity Wedding Rings</NuxtLink>
                            <NuxtLink :to="localePath('/blog/his-and-her-promise-rings-for-couples')">His and Her Promise Rings </NuxtLink>
                            <NuxtLink :to="localePath('/blog/wedding-band-ring-engraving')">Wedding Ring Engraving Ideas  </NuxtLink>
                        </div>
                        <div v-else class="menu_ordered menu_ordered_noicon">
                            <NuxtLink :to="localePath('/blog/understanding-lab-diamonds')">Understanding Lab Diamonds</NuxtLink>
                            <NuxtLink :to="localePath('/blog/know-about-diamond-certification')">Thе Significancе of Diamond Cеrtification </NuxtLink>
                            <NuxtLink :to="localePath('/blog/lab-diamonds-buying-guide')">Shopper’s Guide to Buying Lab Diamonds </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="inner_menu_box inner_menu_four inner_menu_four_mod">
                    <div class="menu_card">
                        <div class="main_menu_image">
                            <picture>
                            <source width="249" height="304" type="image/webp" srcset="~/assets/header/menu_wedding.webp">
                            <img loading="lazy" width="249" height="304" type="image/jpeg" src="~/assets/header/menu_wedding.jpg" alt="Wedding Rings">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    const localePath = useLocalePath()
    const { $country } = useNuxtApp()
    const props = defineProps([
      'openOverlay',
      'hoverFlag',
      'locale'
    ]);

    const hideMenu = ref(false);
    const openMainMenu = ref(false);
    const arrow = ref(true);

    const route = useRoute();
    const emit = defineEmits(['hideMenuInDesktop'])


    watch(route, (to, from) => {
      hideMenu.value = true;
      setTimeout(() => {
        hideMenu.value = false;
      }, 500);
      emit('hideMenuInDesktop');
    });

    watch(() => props.openOverlay, (newVal, oldVal) => {
      if (!oldVal) {
        arrow.value = true;
        openMainMenu.value = false;
      }
    });

    const toggleMenu = () => {
      arrow.value = !arrow.value;
      openMainMenu.value = !openMainMenu.value;
    };
</script>

<style scoped>
.landing_link .inner_menu_box{
    padding: 10px 0 !important;
} 
.main_menu_block ul li a {
    color: #303030;
    font-size: 16px;
    line-height: 1.25;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
}
.inner_menu_box .menu_card{
    border-top: none;
}
.menu_ordered{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
</style>
